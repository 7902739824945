<template>
  <div class="sectionAnswers">
    <div class="all-answers-section" v-if="submissionsList.length > 0">
      <h1>{{ $t("g.answers") }}</h1>

      <div
        class="answersCard mb-3"
        v-for="(submission, index) in submissionsList"
        :key="submission.id"
      >
        <div class="header d-flex justify-content-between">
          <p>{{ $t("g.submission") }} {{ index + 1 }}</p>
          <p class="grade" v-if="submission.submission_grade">
            {{ $t("g.grade") }}:
            <span>{{
              submission.submission_grade.grade.name +
              " " +
              submission.submission_grade.score
            }}</span>
          </p>
        </div>

        <div class="card-content-container bg-white">
          <div
            class="cardContent"
            v-for="(answer, index) in submission.answer_docs"
            :key="answer.id"
            :class="{
              'card-content--border-radius':
                submission.answer_docs.length === index + 1,
              'card-content--first--padding': index === 0,
              'card-content--last--padding':
                submission.answer_docs.length === index + 1,
            }"
          >
            <div class="body">
              <div class="answer d-flex justify-content-between">
                <p>{{ answer.name }}</p>
                <a :href="answer.path" target="_blank">{{ $t("g.view") }}</a>
              </div>

              <hr v-if="submission.answer_docs.length !== index + 1" />
            </div>

            <div
              class="cardFooter"
              v-if="
                submission.submission_grade &&
                submission.answer_docs.length === index + 1
              "
            >
              <div class="d-flex justify-content-between">
                <p>
                  {{ $t("g.corredcted_by") }}
                  <span>{{
                    submission.comments[0].teacher
                      ? `${submission.comments[0].teacher.full_name}`
                      : null
                  }}</span>
                </p>
                <img
                  class="cursor-pointer"
                  :src="collapseBtn"
                  alt="chat"
                  width="25"
                  height="25"
                  @click="toggleCollapse(answer.id)"
                  v-if="submission.comments"
                />
              </div>
              <b-collapse :id="`collapse-${answer.id}`" class="mt-2">
                <div
                  v-if="submission.comments && submission.comments.length > 0"
                >
                  <span
                    class="card-text"
                    style="color: #135991"
                    v-for="comment in submission.comments"
                    :key="comment.id"
                  >
                    {{ comment.text }}
                  </span>
                </div>
                <span v-else>
                  {{ $t("there_is_no_comment") }}
                </span>
              </b-collapse>
            </div>
          </div>
        </div>
      </div>
    </div>

    <p
      class="hint"
      :style="{ paddingBottom: restAttempts === 0 ? '24px' : '0' }"
    >
      {{
        $t("g.answers_hint")
          .replace(":rest_attemps", restAttempts)
          .replace(":attemps", page_data.max_attempts)
      }}
    </p>

    <div
      class="d-flex justify-content-center"
      v-if="submissionsList.length < page_data.max_attempts"
    >
      <label for="upload_submission" class="upload_submissions">{{
        $t("g.upload")
      }}</label>
      <b-form-file
        v-model="submissions"
        class="upload_submissions_input d-none"
        id="upload_submission"
        multiple
      />
      <b-modal
        id="submissions"
        :title="$t('g.submissions')"
        :ok-title="$t('g.Send')"
        :cancel-title="$t('g.cancel')"
        :ok-title-html="okTitleHtml"
        @ok="sendSubmissions"
        :ok-disabled="okTitleHtml !== ''"
      >
        <h4 class="mb-2">{{ $t("g.preview") }}</h4>
        <b-row>
          <b-col v-for="(submission, index) in submissions" :key="index">
            <div
              class="submission-uploaded"
              v-if="submission.type.includes('image')"
            >
              <div class="box-answer">
                <img :src="submissionPath(submission)" alt="" />
                <p class="my-1">
                  {{ submission.name }}
                </p>
                <b-button
                  class="delete-submission-btn d-inline-flex"
                  variant="default"
                  @click="deleteSubmission(index)"
                >
                  <feather-icon icon="XIcon" size="16" />
                </b-button>
              </div>
            </div>
            <b-card
              v-else
              class="align-items-center"
              :img-alt="submission.name"
              :img-src="submissionPath(submission)"
              img-left
              img-height="50"
              img-width="50"
            >
              <b-card-text>{{ submission.name }}</b-card-text>
            </b-card>
          </b-col>
        </b-row>
      </b-modal>
    </div>
  </div>
</template>

<script>
import {
  BCollapse,
  BButton,
  BFormFile,
  BModal,
  BCard,
  BRow,
  BCol,
  BCardText,
} from "bootstrap-vue";

export default {
  name: "SectionAnswers",
  components: {
    BCollapse,
    BFormFile,
    BCardText,
    BButton,
    BModal,
    BCard,
    BRow,
    BCol,
  },
  props: {
    page_data: {
      type: Object,
      required: true,
    },
    enrollment_id: {
      type: Number | String,
      required: true,
    },
  },
  data() {
    return {
      collapseBtn: require("@/assets/images/icons/collapsed.png"),
      isCollapsed: true,
      submissions: [],
      submissionsList: [],
      okTitleHtml: "",
    };
  },
  created() {
    this.getSubmissions();
  },
  methods: {
    toggleCollapse(index) {
      this.$root.$emit("bv::toggle::collapse", `collapse-${index}`);
      this.isCollapsed = !this.isCollapsed;
    },
    submissionPath(submission) {
      console.log(submission);
      return submission.type.includes("image")
        ? URL.createObjectURL(submission)
        : require("@/assets/images/icons/Group.png");
    },
    deleteSubmission(index) {
      this.submissions.splice(index, 1);
      if (this.submissions.length === 0) {
        this.$root.$emit("bv::hide::modal", "submissions");
      }
    },
    sendSubmissions(bvModalEvt) {
      bvModalEvt.preventDefault();
      let formData = new FormData();
      this.submissions.forEach((submission) => {
        formData.append("answer_docs[]", submission);
      });
      this.okTitleHtml = `<span class="spinner-border spinner-border-sm text-light"/>`;
      this.$http
        .post(
          `/student/enrollments/${this.enrollment_id}/assignments/${this.page_data.id}/submissions`,
          formData
        )
        .then((success) => {
          if ([200, 201].includes(success.status)) {
            this.$root.$emit("bv::hide::modal", "submissions");
            this.$helpers.makeToast(
              "success",
              success.data.message,
              success.data.message
            );
            this.$emit("submissionsSent");
            this.submissions = [];
            this.okTitleHtml = "";
            this.getSubmissions();
          }
        })
        .catch((error) => {
          this.$root.$emit("bv::hide::modal", "submissions");
          this.$helpers.handleError(error);
        });
    },
    getSubmissions() {
      this.$http
        .get(
          `student/enrollments/${this.enrollment_id}/assignments/${this.page_data.id}/submissions`
        )
        .then((success) => {
          if ([200, 201].includes(success.status)) {
            this.submissionsList = success.data.data;
          }
        })
        .catch((error) => {
          this.$helpers.handleError(error);
        });
    },
  },
  watch: {
    isCollapsed: function (val) {
      this.collapseBtn = val
        ? require("@/assets/images/icons/collapsed.png")
        : require("@/assets/images/icons/un-collapsed.png");
    },
    restAttempts: function () {
      return +this.page_data.max_attempts - +this.submissionsList.length;
    },
    submissions: function () {
      if (this.submissions.length > 0) {
        this.$root.$emit("bv::show::modal", "submissions");
      }
    },
    "page_data.id": function (val) {
      console.log("id is changed");
      this.getSubmissions();
    },
  },
  computed: {
    restAttempts: function () {
      return this.page_data.max_attempts - this.submissionsList.length;
    },
  },
};
</script>

<style src="./_sectionAnswers.scss" lang="scss" scoped />
